import React, { Component } from 'react';
import { createRoot } from 'react-dom/client'
import { NavMenu } from './NavMenu';
import PaginaHome from './PaginaHome';
import SituazioneIncarichi from './SituazioneIncarichi';

export class Layout extends Component {
  
  static displayName = Layout.name;

  render () {
    return (<React.Fragment>      
        <NavMenu />   
        <div id="bgImageDiv2"></div> 
        <div id='contenutoDinamico2'></div>              
    </React.Fragment>);
  }

  componentDidMount() {   
    sessionStorage.setItem("JFCATMobilePaginaLogin", "false");
    const container = document.getElementById('contenutoDinamico2');
    const root = createRoot(container); 
    root.render(<SituazioneIncarichi></SituazioneIncarichi>);   
  }
}
