/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { MenuComponent, SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { SampleBase } from './sampleBase';
import SituazioneIncarichi from './SituazioneIncarichi';
import PaginaHome from './PaginaHome';
import PaginaLogin from './PaginaLogin';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import FlagItaly from '../assets/Flag_Italy4.png';
import FlagUK from '../assets/Flag_UK4.png';
import FlagItaly2 from '../assets/Flag_Italy3.png';
import FlagUK2 from '../assets/Flag_UK3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CustomJS from '../Custom';
import logoCatNavbar  from '../assets/JFTcatNavbar.png'

export class NavMenu extends SampleBase {
    constructor(props) {
        super(...arguments);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.dataList = [
            { text: 'Home' },
            { text: 'About' },
            { text: 'Careers' },
            { text: 'FAQs' },
            { text: 'Blog' },
            { text: 'Uses' },
            { text: 'Contact' }
        ];
        this.fields = { tooltip: 'text' };
        this.menuItems = [
            { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
            { id: "id2", text: 'Situazione Incarichi', iconCss: "fas fa-toolbox", pagina: "SituazioneIncarichi" },
            { id: "id3", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
        ];
        this.childKey = 0;
        this.voceMenu = 0;
    }
    state = {
        collapsed: true
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    cambiaLinguaApplicativo(lingua) {

    }
    openClick() {
        this.sidebarobj.show();
    }
    onSelectMenu(args) {
        this.sidebarobj.hide();
        let componente, divContainer = '';
        ++this.childKey;
        switch (args.item.pagina) {
            case 'Home':
                if (this.voceMenu === 1)
                    return;
                if (!CustomJS.isNullEmpty(document.getElementById('defaultDialogDoc3')))
                    document.getElementById('defaultDialogDoc3').ej2_instances[0].destroy();
                if (!CustomJS.isNullEmpty(document.getElementById('defaultDialogDoc1')))
                    document.getElementById('defaultDialogDoc1').ej2_instances[0].destroy();
                componente = <PaginaHome key={this.childKey}></PaginaHome>;
                divContainer = 'contenutoDinamico2';
                this.voceMenu = 1
                break;
            case 'SituazioneIncarichi':
                if (this.voceMenu === 2)
                    return;
                if (!CustomJS.isNullEmpty(document.getElementById('defaultDialogDoc3')))
                    document.getElementById('defaultDialogDoc3').ej2_instances[0].destroy();
                if (!CustomJS.isNullEmpty(document.getElementById('defaultDialogDoc1')))
                    document.getElementById('defaultDialogDoc1').ej2_instances[0].destroy();
                componente = <SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>;
                divContainer = 'contenutoDinamico2';
                this.voceMenu = 2
                break;
            case 'Login':
                sessionStorage.removeItem('JellyfishTechMobileAuthenticationPayload');
                sessionStorage.removeItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi');
                componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
                divContainer = 'contenutoDinamico1';
                this.voceMenu = 0;
                break;
            default:
                break;
        }
        const container = document.getElementById(divContainer);
        const root = createRoot(container);         
        root.render(<CallbackWrapper callback={() => {
            if (divContainer === 'contenutoDinamico1')
                window.location.reload(true);
        }}>{componente}</CallbackWrapper>);  
    }
    onCreatedSideBar(args) {
        this.element.style.visibility = "visible";
    }

    render() {
        return (
            <React.Fragment>
                <div className="header-section container-fluid" id='header'>
                    <div className="row">
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                            <span id="hamburger" className="fas fa-bars fa-2x icon list" style={{ paddingLeft: '25px' }} onClick={this.openClick.bind(this)} ></span>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1">
                            <img src={logoCatNavbar} height="auto" width="150vw" style={{ marginLeft: '-10px', marginTop: '5px' }} />
                        </div>
                        <div id='titoloPagina' className="col-xs-6 col-sm-6 col-md-8 col-lg-8 text-center">
                            <span style={{ paddingTop: '30px', fontSize: '160%', fontWeight: 'bolder', color: '#133B95' }}>Jellyfish C.A.T. Mobile</span>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-end">
                            <img src={LogoDigitalPraesidium} style={{ marginRight: '20px' }} height="auto" width="140vw" />
                        </div>
                    </div>
                    <SidebarComponent id='sidebar-menu' ref={Sidebar => this.sidebarobj = Sidebar} created={this.onCreatedSideBar} showBackdrop={true} closeOnDocumentClick={true} type='Over' width="220px">
                        <div className="title-header">
                            <div className="main-menu">
                                <div>
                                    <div id="listcontainer">
                                        <MenuComponent id='main-menubar' orientation='Vertical' items={this.menuItems} cssClass='dock-menu' select={this.onSelectMenu.bind(this)}></MenuComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SidebarComponent>
                </div>
            </React.Fragment>
        );
    }
    componentDidMount() {
        this.voceMenu = 2;
    }
}