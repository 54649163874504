/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { SampleBase } from './sampleBase';
import LogoACT from '../assets/LogoAllCTechnology.jpg';

export default class Home extends SampleBase {
    constructor() {
        super(...arguments);
        this.LogoSocieta = LogoACT;        
    }
    state = {        
    }

    render () {        
        return (<React.Fragment>  
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content">
                                    <div className='row'></div>
                                    <div className='row'>
                                        <div className="col-xs-6 col-sm-6 col-lg-4 col-md-4">
                                            <img id='imgLogo' src={this.LogoSocieta} style={{ paddingTop: '20px', paddingLeft: '20px' }} width='100%' height='auto'></img>
                                        </div>
                                    </div>                                    
                                    <div className='row'></div>
                                    <div className='row'>
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <span style={{ fontSize: '4.0vw', fontWeight: 'bolder', paddingLeft: '15px', color:'#133B95'}}>C.A.T. {JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'></div>
                                    <div className='row'>
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    rendereComplete() {
        
    }
}
