import React from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ErrorBoundary from './errorBoundary';
import 'underscore/underscore-min';
import 'bootstrap/dist/css/bootstrap.css';
import './BlockUI.css';
import './Custom.css';
import './assets/fontawesome/css/all.min.css';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5ccXVVRmNZV0J3VkE=');

const rootElement = document.getElementById('root');

const root = createRoot(rootElement); 
root.render(<BrowserRouter>
   <ErrorBoundary>
      <App />
   </ErrorBoundary>
</BrowserRouter>);

registerServiceWorker();

